.cardSection {
    display: flex;
    flex-direction: column;
    position: relative;
}

.cardSection a {
    margin: 1rem 0;
    display: inline-block;
    width: 100%;
    text-decoration: none;
    margin-bottom: 2rem;
}

.cardSection a:hover {
    text-decoration: none;
}

.cardSection:hover .card:not(:hover) {
    opacity: 0.5;
}

.card {
    display: flex;
    align-items: flex-start;
    margin: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    width: 100%;
    position: relative;
}

.card h4 {
    margin-right: 1rem;
}

.card:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.card:hover h4 {
    color: var(--primary-accent);
    transition: color 0.2s;
}

.card:hover h5 {
    color: var(--primary-accent);
    transition: color 0.2s;
}

.date {
    flex-basis: 125px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 1rem;
    align-self: stretch;
    font-size: 13px;
}

.startDate,
.endDate {
    color: var(--text-dark-gray);
    width: 100%;
    text-align: center;
}

.timeline {
    position: absolute;
    height: calc(100% - 6rem);
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    background-color: var(--text-dark-gray);
    z-index: -1;
}

.details {
    flex: 1;
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.details h4 {
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.details h5 {
    font-size: 1.15rem;
    color: rgba(255, 255, 255, 0.76);
    font-weight: 300;
}

.details p {
    color: var(--text-gray);
    margin-bottom: 1rem;
}

.technologies {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
    padding: 0;
    margin: 0;
}

.technologies li {
    background-color: rgba(37, 204, 118, 0.1);
    color: var(--primary-accent);
    border-radius: 12px;
    padding: 0.25rem 0.5rem;
    list-style-type: none;
}

.externalLinkIcon {
    font-size: 1rem;
    color: white;
    transition: transform 0.2s, color 0.2s;
    position: absolute;
    right: 20px;
    top: 20px;
}

.card:hover .externalLinkIcon {
    color: var(--primary-accent);
    transform: scale(1.3);
}

.thumbnail {
    flex-basis: 125px;
    flex-shrink: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
}

.thumbnail img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.tallSpacer {
    height: 6rem;
}

.spacer {
    height: 3rem;
}

.sectionHeader {
    display: none;
}

.divider {
    content: "";
    display: block;
    height: 2px;
    background: var(--secondary-accent);
    opacity: 0.5;
}

.textLink {
    color: white;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.2s;
}

.textLink:hover {
    color: var(--primary-accent);
}

@media (max-width: 992px) {
    .hiddenCol {
        display: none;
    }

    .spacer {
        height: 4rem;
    }

    .stickyWrapper {
        position: relative;
    }

    .sectionHeader {
        position: sticky;
        top: 0;
        color: var(--highlight);
        z-index: 10;
        padding: .5rem 0rem;
        font-size: 1.25rem;
        font-weight: bold;
        text-align: left;
        display: block;
        
    }

    .blurEffect {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 3rem;
        backdrop-filter: blur(10px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        z-index: 9;
    }

    .hidden {
        display: none;
    }
}