.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 6rem;
    height: calc(100vh - 12rem);
}

.name {
    text-decoration: none;
    color: white;
}

.name h1 {
    font-size: 2.75rem;
    font-weight: bold;
}

.position {
    font-size: 1.5rem;
    color: rgb(219, 219, 219);
}

.description {
    font-size: 1rem;
    margin-right: 6rem;
}

.navItem {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;
    color: var(--text-gray);
    text-decoration: none;
    position: relative;
    overflow: hidden;
    transition: color 0.3s, font-weight 0.3s, transform 0.3s;
    padding-left: 14px;
}

.navItem::before {
    content: '';
    position: absolute;
    top: 0;
    left: -4px;
    width: 4px;
    height: 100%;
    background-color: var(--primary-accent);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s, left 0.3s;
}

.navItem:hover::before,
.navItem.active::before {
    transform: scaleX(1);
    left: 0px;
}

.navItem:hover,
.navItem.active {
    color: var(--primary-accent);
    transform: translateX(25px) scale(1.1);
}

.topContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.socialContainer {
    margin-top: auto;
}

.socialIcon {
    color: var(--text-gray);
    margin-right: 1rem;
    font-size: 2rem;
    transition: color 0.3s;
}

.socialIcon:hover {
    color: white;
}

.textLink,
.textLink svg {
    text-decoration: none;
    color: var(--highlight);
    transition: all 0.2s ease-in-out;
    font-size: 18px;
}

.textLink:hover {
    color: var(--primary-accent);
}

.textLink:hover svg {
    color: var(--primary-accent);
    transform: translateX(.5rem);
}

@media (max-width: 992px) {
    .navContainer {
        display: none;
    }

    .container {
        height: 100%;
        margin-top: 3rem;
    }

    .textLink {
        margin-bottom: 1rem;
    }
}